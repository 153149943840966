<template>
  <div class="dp-ag-grid-date-filter">
    <template>
      <input
        type="date"
        v-model="fromDate"
        style="width: 100%; outline: none; background-color: #ffffff"
        class="dp-ag-grid-date-input"
        @change="updateFilter"
      />から
      <input
        type="date"
        v-model="toDate"
        style="width: 100%; outline: none; background-color: #ffffff"
        class="dp-ag-grid-date-input"
        @change="updateFilter"
      />
      <label
        ><input
          type="checkbox"
          v-model="ambiguous"
          filter-checkbox="true"
          @change="updateFilter"
          class="dp-ag-grid-input-pad"
        />曖昧日付を含む</label
      >
      <p id="dp-ag-grid-date-filter-error" class="dp-ag-grid-date-error"></p>
    </template>
  </div>
</template>

<style scoped>
.dp-ag-grid-date-filter {
  padding: 12px 12px 6px 12px;
  width: 160px;
}
.dp-ag-grid-date-input {
  border: solid 1px #bbb;
  border-radius: 5px;
  height: 30px;
  min-width: 35px;
  outline: none;
  appearance: auto;
  -moz-appearance: auto;
  -webkit-appearance: auto;
  margin: 0px 0px 6px 0px;
}
.dp-ag-grid-date-filter:focus {
  box-shadow: 0 0 2px 0.1rem rgb(33 150 243 / 40%);
}
.dp-ag-grid-input-pad {
  margin: 6px 6px 0px 0px;
}
.dp-ag-grid-date-error {
  margin: 0px 0px 6px 0px;
  color: red;
}
</style>

<script>
// import Vue from "vue";
import VueJS from "vue/dist/vue.js";
import moment from "moment";
import DateUtils from "../../../utils/DateUtils";

export default VueJS.extend({
  data: function () {
    return {
      fromDate: "",
      toDate: "",
      ambiguous: true,
    };
  },

  methods: {
    updateFilter() {
      this.params.filterChangedCallback();
    },
    isFilterActive() {
      return this.fromDate || this.toDate;
    },
    doesFilterPass(params) {
      let retFrom = true;
      let retTo = true;
      let error = false;
      const colId = this.params.column.colId;
      const target = params.data[colId];
      if (!this.ambiguous && !moment(target, "YYYY/MM/DD", true).isValid()) {
        return false;
      }
      const targetDate = DateUtils.changeDate(target);

      // FROM日付フィルター
      if (this.fromDate && DateUtils.dateComapre(this.fromDate, targetDate, true) > 0) {
        retFrom = false;
      }

      // FROM日付とTO日付フィルターの整合性チェック（不整合時はTO日付を無効にする）
      var msgNode = document.getElementById("dp-ag-grid-date-filter-error");
      if (msgNode) {
        msgNode.innerHTML = "";
      }

      if (this.fromDate && this.toDate) {
        const compFromTo = DateUtils.dateComapre(this.fromDate, this.toDate);
        if (compFromTo > 0) {
          msgNode.innerHTML = "日付を正しく指定してください。";
          error = true;
        }
      }

      // TO日付フィルター
      if (this.toDate && !error && DateUtils.dateComapre(this.toDate, targetDate, false) < 0) {
        retTo = false;
      }

      return retFrom && retTo;
    },
    getModel() {
      return { value: { fromDate: this.fromDate, toDate: this.toDate } };
    },
    setModel(model) {
      this.fromDate = model ? model.fromDate : "";
      this.toDate = model ? model.toDate : "";
    },
  },
});
</script>
