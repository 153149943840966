import moment from "moment";

const comapre = (a, b) => {
  var ret = 0;
  if (a === b) {
    return ret;
  } else {
    return a < b ? -1 : 1;
  }
};

const dateComapre = (a, b, nagative = false) => {
  var ret = null;
  if (Array.isArray(b)) {
    for (const elm of b) {
      const tmp = comapre(a, elm);
      if (ret != null && ret != tmp) {
        if (nagative) {
          ret = -2;
        } else {
          ret = 2;
        }
      } else {
        ret = tmp;
      }
    }
    return ret;
  } else {
    return comapre(a, b);
  }
};

const changeDate = (date) => {
  if (!date) {
    return null;
  }

  var ret = [];

  if (moment(date, "YYYY/MM/DD", true).isValid()) {
    ret.push(moment(date).format("YYYY-MM-DD"));
    return ret;
  }

  var sdateStr = "";
  var last = false;

  if (date.endsWith("/上")) {
    sdateStr = date.replace("/上", "/01");
  } else if (date.endsWith("/中")) {
    sdateStr = date.replace("/中", "/11");
  } else if (date.endsWith("/下")) {
    sdateStr = date.replace("/下", "/21");
    last = true;
  } else if (date.endsWith("/予定")) {
    sdateStr = date.replace("/予定", "/01");
    last = true;
  } else if (date.endsWith("未定")) {
    return ret;
  }

  var sDate = moment(sdateStr, "YYYY/MM/DD", null);
  var eDate = sDate.clone();
  if (last) {
    eDate.endOf("month");
  } else {
    eDate.add(9, "days");
  }

  var tmpDate = sDate.clone();
  while (eDate.isSameOrAfter(tmpDate)) {
    ret.push(tmpDate.format("YYYY-MM-DD"));
    tmpDate.add(1, "days");
  }
  return ret;
};

export default { comapre, dateComapre, changeDate };
